import { useState, useRef } from "react";
import { DateTime } from "luxon";
import html2canvas from "html2canvas";
import logo from "./logo.png";
import face from "./face-logo.svg";
import insta from "./insta-logo.svg";
import "./App.css";
import "./responsive.css";
import axios from "axios";

const App = () => {
	const cardComponentRef = useRef();
	const [cpf, setCpf] = useState("");
	const [user, setUser] = useState();
	const [notFound, setNotFound] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isExpired, setIsExpired] = useState(false);

	const onChangeCpf = (v) => {
		if (v.length <= 14) {
			v = v.replace(/\D/g, "");

			v = v
				.replace(/(\d{3})(\d)/, "$1.$2")
				.replace(/(\d{3})(\d)/, "$1.$2")
				.replace(/(\d{3})(\d)/, "$1-$2");

			setCpf(v);
		}
	};

	const submit = () => {
		setIsLoading(true);
		setNotFound(false);
		axios
			.get(
				`https://somosciclistas.com.br/api/carteirinha?cpf=${cpf.replace(
					/\D/g,
					""
				)}&cpfWithPoints=${cpf}`
			)
			.then((result) => {
				setIsLoading(false);
				if (result.data?.participante) {
					setUser(result.data?.participante);
					setIsExpired(
						calculateExpirationDate(
							result.data?.participante.created_at
						)
					);
				} else {
					setNotFound(true);
				}
			});
	};

	const calculateValid = (date) => {
		return DateTime.fromISO(date).plus({ year: 1 }).toFormat("dd/LL/yyyy");
	};

	const calculateExpirationDate = (date) => {
		return (
			DateTime.fromISO(date).plus({ year: 1 }).startOf("day") <
			DateTime.now().startOf("day")
		);
	};

	const download = () => {
		var vp = document
			.getElementById("viewportMeta")
			.getAttribute("content");
		document
			.getElementById("viewportMeta")
			.setAttribute("content", "width=800");

		html2canvas(cardComponentRef.current, {
			backgroundColor: "#FFFFFF",
		})
			.then((canvas) => {
				document.body.appendChild(canvas);
				saveScreenshot(canvas);
			})
			.then(function () {
				document
					.getElementById("viewportMeta")
					.setAttribute("content", vp);
			});
	};

	const saveScreenshot = (canvas) => {
		const fileName = "Meu cartão";
		const link = document.createElement("a");
		link.download = fileName + ".png";
		//console.log(canvas);
		canvas.toBlob(function (blob) {
			console.log(blob);
			link.href = URL.createObjectURL(blob);
			link.click();
		});
	};

	const CardComponent = () => (
		<div className="card" ref={cardComponentRef}>
			<div className="top">
				<h3>Associação de Ciclistas de Poços de Caldas</h3>
				<h4>Cartão de Associado</h4>
			</div>
			<div className="bottom">
				<h3>{user.nome + " " + user.sobrenome}</h3>
				<p>{user.cpf}</p>
				<p>Tipo Sanguíneo: {user.tipo_sanguineo}</p>
				<p>
					Valido até <b>{calculateValid(user.created_at)}</b>
				</p>
			</div>
			<div className="logo">
				<img src={logo} alt="Logo" />
			</div>
		</div>
	);

	const ModalComponent = () => (
		<div className="modal">
			<div className="cardModal">
				<p>Anuidade vencida, renove aqui sua anuidade:</p>
				<a
					className="button"
					href="http://somosciclistas.com.br/cartao-ciclista"
					target="_blank"
					rel="noreferrer"
				>
					Renovar
				</a>
			</div>
		</div>
	);

	if (user) {
		return (
			<div className="container">
				<button className="button" onClick={() => download()}>
					Download do Cartão
				</button>
				<CardComponent />
				<div className="containerText">
					<div className="firsBlock">
						<p>
							<b>#1.</b> Este cartão é intransferível
						</p>
						<p>
							<b>#2.</b> Este cartão lhe dá benefícios em todas as
							empresas parceiras da ACPC, basta apresentá-lo no
							momento da compra.
						</p>
						<p>Após sua validade, deverá renovar sua assinatura.</p>
					</div>
					<div className="secondBlock">
						<p>Consulte o site e saiba mais</p>
						<p>
							<a
								href="https://somosciclistas.com.br"
								target="_blank"
								rel="noreferrer"
								className="website"
							>
								somosciclistas.com.br
							</a>
						</p>
						<div className="social">
							<a
								href="https://pt-br.facebook.com/somosciclistaspc/"
								target="_blank"
								rel="noreferrer"
							>
								<img src={face} alt="Facebook" width={30} />
							</a>
							<a
								href="https://www.instagram.com/somosciclistaspc/"
								target="_blank"
								rel="noreferrer"
							>
								<img src={insta} alt="Instagram" width={30} />
							</a>
							<p className="hashtag">#somosciclistaspc</p>
						</div>
					</div>
					<p className="footer">
						Apresente esse cartão junto com um documento de
						identidade.
					</p>
				</div>
				{isExpired && <ModalComponent />}
			</div>
		);
	}

	return (
		<div className="container">
			<p className="loginTitle">Digite o seu CPF</p>
			<input
				type="text"
				className="input"
				value={cpf}
				onChange={(e) => onChangeCpf(e.target.value)}
			/>
			{notFound && <p className="error">Não encontrado</p>}
			<button
				className={isLoading ? "buttonDisabled" : "button"}
				disabled={isLoading}
				onClick={() => submit()}
			>
				{isLoading ? "Carregando" : "Próximo"}
			</button>
		</div>
	);
};

export default App;
